<template>
    <div class="row">
        <div class="col d-flex align-items-center">
            <div class="pull-left">
                <h5 :style="{ marginLeft: sideMargin + 'px', marginRight: sideMargin + 'px' }">
                    <b-link @click.prevent="historyBack" v-if="goBack">{{ title }} </b-link>
                    <b-link :to="{ name: routeName }" v-else-if="routeName">{{ title }} </b-link>
                    <span v-else>{{ title }}</span>
                </h5>
            </div>
        </div>
    </div>
</template>
<script>
// import backEndApi from "../../../../../api/backEndApi";

export default {
    components: {},
    props: {
        title: {
            type: String,
            required: true,
        },
        goBack: {
            type: Boolean,
            default: false,
        },
        routeName: {
            type: String,
            default: null,
        },
        sideMargin: {
            type: [Number, String],
            default: 20,
        },
    },
    watch: {},
    data() {
        return {};
    },
    computed: {},
    created() {},
    methods: {
        historyBack() {
            this.$router.back();
        },
    },
};
</script>

<style scoped>
h5 {
    margin-top: 10px;
}
</style>
